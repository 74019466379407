@font-face {
  font-family: "CustomFont";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

body {
  overflow: hidden;
  font-family: "CustomFont", sans-serif;
  margin: 0;
}
