.header_parent {
  width: 90%;
  height: 8rem;
  border-bottom: 1px solid #8080803d;
  background-color: transparent;
  transition: width 0.5s, background-color 0.5s;

  margin-left: 5%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    width: max-content;
    display: block;
    height: 7em;
    width: 18em;
    margin-left: 1rem;
  }
  & > :nth-child(2) {
    flex: 1;
  }
  background-color: #353a4e;
  color: white;
  text-align: center;
  padding: 20px 0;

  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 3% 5% 5% 5%;
  transition: width 0.5s, padding 0.5s, margin 0.5s, border-radius 0.9s,
    background-color 0.5s;
  width: 90%;

  & * a {
    color: white;
  }
  & * .active {
    color: white !important;
    &::after {
      position: absolute !important;
      content: "" !important;
      height: 0.5rem !important;
      width: 100% !important;

      border-top: 2px solid white !important ;

      left: 0 !important;
      top: 1% !important;
    }
  }
}

.logo {
  background-image: url(../../assets/Logo1.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.navigation {
  padding: 1rem;
  padding-top: 0.5px;
  display: flex;
  width: max-content;
  gap: 2rem;
  display: flex;
  justify-content: end;
  & > a {
    font-size: 1.1em;
    text-decoration: none;
    color: gray;
    padding-top: 0.5em;
    margin-top: -1px;
    position: relative;
  }
}
.active {
  color: black !important;
  font-weight: bold !important;
  &::after {
    position: absolute;
    content: "";
    height: 0.5rem;
    width: 100%;

    border-top: 2px solid black !important;

    left: 0;
    top: 1%;
  }
}

.shrink_headers {
  background-color: #353a4e;
  color: white;
  text-align: center;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0.2% 5% 0 5%;
  transition: width 0.5s, padding 0.5s, margin 0.5s, border-radius 0.9s,
    background-color 0.5s;
  width: 90%;

  & * a {
    color: white;
  }
  & * .active {
    color: white !important;
    &::after {
      position: absolute !important;
      content: "" !important;
      height: 0.5rem !important;
      width: 100% !important;

      border-top: 2px solid white !important ;

      left: 0 !important;
      top: 1% !important;
    }
  }
}
.toggleButton {
  width: max-content;
  flex: none !important;
  font-size: 2rem;
}

.mobile_version_size {
  flex: 1;
}
