.parent_component {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;

  background-color: #eae7dc;
}
.main_component {
  width: 100vw;
  box-sizing: border-box;
}
