.blogcard_parent {
  display: flex;
  flex-basis: auto;
  height: max-content;
  width: 15rem;
  background-color: #eae7dc;
  height: 27rem;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 2px solid rgba(0, 0, 0, 0.128);
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
}

.prod_image {
  background-repeat: no-repeat;
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.title {
  border-radius: 10px;
  background: rgb(38, 96, 100);
  padding: 2px 12px 2px 12px;
  color: white;
  width: max-content;
}
.desc {
  font-size: 0.8rem;
}
.heading {
  font-size: 0.8rem;
  font-weight: bolder;
}
