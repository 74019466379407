.banner {
  background-image: url(../../assets/banner1.png);
  background-repeat: no-repeat;
  width: 100%;

  height: 29rem;

  background-size: contain;
}
.blogs_container {
  height: max-content;
  display: flex;
  flex: 1;
  margin: 0;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}
.mobile_view {
  justify-content: center !important;
}
