.footer {
  padding: 1rem;
  flex-direction: column;
  height: max-content;
  padding-left: 4.6rem;
  background-color: #353a4e;
  width: 100%;
  display: flex;
  gap: 1rem;
  position: relative;
  &::after {
    content: "Copyright © 2024, All Right Reserved";
    position: absolute;
    border-top: 1px solid gray;
    text-align: center;
    top: 100%;
    background-color: #353a4e;
    width: 100%;
    left: 0;
    padding: 1rem 0 1rem 0;
    color: white;
  }

  & > a {
    text-decoration: none;
    color: white;
    display: flex;
  }
}
.nav_header {
  font-size: 1.1rem;
  text-decoration: underline;
  color: white;
  font-weight: bolder;
}
